import {
  ChannelType,
  Discount,
  OrderType,
  ServiceType,
} from '@open-tender/types'
import {
  makeLocalDateStr,
  formatDateStr,
  makeImageMap,
} from '@open-tender/utils'
import { openModal, useAppDispatch } from '@open-tender/cloud'
import { Tag } from 'components'
import {
  RewardButton,
  RewardChannel,
  RewardContent,
  RewardDash,
  RewardImage,
  RewardNote,
  RewardTag,
  RewardTitle,
  RewardView,
  Expiration,
  RewardLocked,
  RewardLockedText,
} from './Reward.styled'
import { Lock } from '../icons'
import { isExpired } from 'utils/helpers'

const makeServiceType = (serviceType: ServiceType | null) => {
  switch (serviceType) {
    case 'WALKIN':
      return 'In-store only'
    case 'PICKUP':
      return 'Pickup only'
    case 'DELIVERY':
      return 'Delivery only'
    default:
      return null
  }
}

const makeOrderType = (orderType: OrderType | null) => {
  switch (orderType) {
    case 'OLO':
      return null
    case 'CATERING':
      return 'Catering only'
    case 'MERCH':
      return 'Merch only'
    default:
      return null
  }
}

const makeChannelType = (channelType: ChannelType | null) => {
  switch (channelType) {
    case 'APP':
      return <RewardChannel>App Only!</RewardChannel>
    case 'ONLINE':
      return <RewardChannel>Online Only!</RewardChannel>
    case 'POS':
      return <RewardChannel>At POS Only!</RewardChannel>
    case 'KIOSK':
      return <RewardChannel>Kiosk Only!</RewardChannel>
    default:
      return null
  }
}

export const makeLimitations = (item: Discount) => {
  const { channel_type, order_type, service_type } = item
  const serviceType = makeServiceType(service_type)
  const orderType = makeOrderType(order_type)
  const channelType =
    channel_type && makeChannelType(channel_type as ChannelType)
  const comma = serviceType && orderType ? ', ' : null
  const dash =
    (serviceType || orderType) && channelType ? (
      <RewardDash>-</RewardDash>
    ) : null
  if (serviceType || orderType || channelType) {
    return (
      <>
        {serviceType}
        {comma}
        {orderType}
        {dash}
        {channelType}
      </>
    )
  }
  return 'Valid on all Orders'
}

const makeReward = (item: Discount) => {
  const imageMap = makeImageMap(item.images)
  const imageUrl =
    imageMap.SMALL_IMAGE || imageMap.APP_IMAGE || imageMap.LARGE_IMAGE
  const expiration = item.expires_at
    ? formatDateStr(item.expires_at.split('T')[0], 'MMM d, yyyy')
    : null
  const limitations = makeLimitations(item)
  return { ...item, imageUrl, expiration, limitations }
}

export type RewardWithLimitations = ReturnType<typeof makeReward>

export type RewardEnhanced = Omit<RewardWithLimitations, 'limitations'>

const Reward = ({
  item,
  withoutImage,
}: {
  item: Discount
  withoutImage?: boolean
}) => {
  const dispatch = useAppDispatch()
  const today = makeLocalDateStr(new Date(), 0, 'yyyy-MM-dd')
  const reward = makeReward(item)
  const expired = isExpired(reward.expires_at, reward.end_date)
  const todayOnly = reward.expires_at === today
  const bgStyle = reward.imageUrl
    ? { backgroundImage: `url(${reward.imageUrl}` }
    : undefined

  const redeem = () => {
    const { limitations, ...rest } = reward
    const args = { reward: rest }
    dispatch(openModal({ type: 'reward', args }))
  }

  const expiration = reward.expires_at ? (
    reward.expires_at === today ? (
      <Expiration color="tertiary">Today only</Expiration>
    ) : reward.expires_at ? (
      <Expiration color="tertiary">Expires {reward.expiration}</Expiration>
    ) : null
  ) : null

  return (
    <RewardButton
      disabled={expired}
      onClick={redeem}
      label={`Apply ${reward.name}`}
    >
      {expired && (
        <RewardLocked>
          <span>
            <Lock size={22} strokeWidth={1.5} />
          </span>
          <RewardLockedText>Expired</RewardLockedText>
        </RewardLocked>
      )}
      <RewardView>
        <RewardTag>
          {todayOnly && (
            <Tag text="Today only!" icon={undefined} color="alert" />
          )}
        </RewardTag>
        {!withoutImage && <RewardImage style={bgStyle} />}
        <RewardContent>
          <RewardTitle as="p" className="title" color="primary">
            {reward.title}
          </RewardTitle>
          <RewardNote as="div" color="tertiary">
            {reward.limitations}
          </RewardNote>
          {!!expiration && expiration}
        </RewardContent>
      </RewardView>
    </RewardButton>
  )
}

export default Reward
